<template>
<div>

  <Navbar :nav-light="true" />



  <section class="section">
  <div class="container">

  <div class="d-flex mt-5 media align-items-center justify-content-between">


        <div class="form-group">
          <label>Вакансия</label>
          <select class="form-control custom-select" v-model="savedSearch">
            <option v-bind:value="x" v-for="x in optionsSavedSearch">{{x.value}}</option>
          </select>
        </div>

    <a color="primary" @click="updateFunnel" class="btn btn-primary" >
      <b-spinner v-if="loadingStatus"></b-spinner> Обновить</a>

        <h5 class="mb-0">Статистика резюме:</h5>
    <div class="">

      <h5 class="mb-0">{{ totalResumes }}</h5>
      <p class="text-muted mb-0">Резюме</p>
    </div>
    <div class="">

      <h5 class="mb-0">{{ totalDelivered }}</h5>
      <p class="text-muted mb-0">Доставлено</p>
    </div>

    <div class="">

      <h5 class="mb-0">{{ totalOpened }}</h5>
      <p class="text-muted mb-0">Открыто</p>
    </div>
    <div class="">

      <h5 class="mb-0">{{ totalClicked }}</h5>
      <p class="text-muted mb-0">Кликов</p>
    </div>


  </div>





  <div class="table-responsive bg-white shadow rounded">

    <table class="table mb-0 table-center">
      <thead>
      <tr>
        <th scope="col">Имя</th>
        <th scope="col">Почта</th>
        <th scope="col">Отправлено</th>
        <th scope="col">Получено</th>
        <th scope="col">Открыто</th>
        <th scope="col">Кликов</th>

        <th scope="col">Дата</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(message,ix) in messages">

        <td @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
          <a   class="text-primary ml-3" @click="goToResume(message.resume_id)"
          >{{message.name}}<i class="uil uil-arrow-right"></i></a>
        </td>
        <td>  {{message.email}}</td>
        <td>  {{message.isSent}}</td>
        <td>  {{message.isDelivered}}</td>
        <td>  {{message.isOpened}}</td>
        <td>  {{message.isClicked}}</td>

        <td>  {{message.eventDate}}</td>
      </tr>
      </tbody>
    </table>
  </div>


  </div>
  </section>


  <Footer />


</div>
</template>

<script>

import Navbar from "@/components/navbar0";
import Footer from "@/components/footer";

import store from "@/store/store";

import {BSpinner, BToast,BToaster} from 'bootstrap-vue'

export default {
  name: "EmailAnalytics",

  components: {
    Navbar,
    Footer,
    BSpinner, BToast,BToaster
  },

  data(){
    return{

      hover: false,
      totalResumes: 0,
      totalDelivered: 0,
      totalOpened: 0,
      totalClicked: 0,
      totalReacted: 0,


      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3
      },

      inputSqlContacts: {
        id: 1,
        user_id: 1,
        savedResumeForSearch: 0,
        keywords: ['продукт', 'product'],
        keywords_str: '',
        city: 'Москва',
        age: [19,30],
        experience: [2,10],
        searchDirection: 1,
        resumeIsSaved: false
      },

      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },


      input_save_search: {
        type_id: 0,
        user_id: 1,
        toDelete: false,
        name: '',
        id: 0,
      },

      savedSearch: {name:'Базовый',id:0,type_id:0},

      optionsSavedSearch: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      selectedResumes:0,
      candidatesResume: {},

      activeVacancies: [],
      allVacancies: [],

      funnelSQL: {
        id: 1
      },
      messages: [],

      loadingStatus: false,
    }
  },

  mounted() {
    this.getAllVacancies();
  },


  watch: {
    'savedSearch.value': function (newVal, oldVal){
      this.selectFunnel();
    },

  },


  methods: {

    goToResume(x) {
      let route = this.$router.resolve({path: '/resume/'+x});
      window.open(route.href, '_blank');
    },

    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/sendgrid/get-funnel-select?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {

        this.optionsSavedSearch = res.data.vacancyOptions;
        this.savedSearch = this.optionsSavedSearch[0];
        console.log(res.data)

      });
    },


    selectFunnel() {

      this.funnelSQL.id  = this.savedSearch.id
      this.funnelSQL.user_id  = store.state.user_id;

      this.totalDelivered = 0;
      this.totalOpened = 0;
      this.totalClicked = 0;
      this.totalSent = 0;

      this.loadingStatus = true;
      //this.$vs.loading({
      //  type: 'default'
      //});

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/sendgrid/get-funnel-new?access_token=' + store.state.token,
        data: this.funnelSQL,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        console.log(res.data)
        this.messages = res.data;
        if (res.data.length){
          this.totalResumes = res.data.length;
          for(var i =0; i< res.data.length; i++){
            if (res.data[i].isDelivered>0){
              this.totalDelivered += 1;
            }
            if (res.data[i].isOpened>0){
              this.totalOpened += 1;
            }
            if (res.data[i].isClicked>0){
              this.totalClicked += 1;
            }
            if (res.data[i].isSent>0){
              this.totalSent += 1;
            }
          }

        }
       // this.$vs.loading.close();
        this.loadingStatus = false;
      }).catch((err) => {
        console.error(err);
        //this.$vs.loading.close();
        this.loadingStatus = false;
       // this.$router.push('login')
      });

    },

    updateFunnel() {

      this.funnelSQL.id  = this.savedSearch.id
      this.funnelSQL.user_id  = store.state.user_id;


     // this.$vs.loading({
     //   type: 'default'
     // });
      this.loadingStatus = true;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/sendgrid/update-funnel-new?access_token=' + store.state.token,
        data: this.funnelSQL,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        if (res.data.status === 'Done!') {
          this.selectFunnel();
          //this.$vs.notify({
          //  time: 4000,
          //  title: 'Успех',
          //  text: 'Данные Обновлены',
          //  color: 'success'
          //});
          this.$bvToast.toast('Данные обновлены', {
            title: `Успех`,
            variant: 'success',
            solid: true,
            autoHideDelay: 8000,
          });
        }
       // this.$vs.loading.close();
        this.loadingStatus = false;
      }).catch((err) => {
        console.error(err);
        //this.$vs.loading.close();
        this.loadingStatus = false;
        //this.$router.push('funnel')
      });
    }

  }


}
</script>

<style scoped>

</style>